<template>
  <div>
    <nav>
      <ul>
        <AccountNavLink :route-to="{name: 'account-dashboard'}" :icon="HomeIcon">
          Dashboard
        </AccountNavLink>
        <AccountNavLink :route-to="{name: 'account-my-account'}" :icon="SliderIcon" :hide-in-delegate-mode="true">
          Account
        </AccountNavLink>
        <AccountNavLink :route-to="{name: 'account-notifications'}" :icon="BellIcon">
          <span>Notifications</span>
          <span v-if="getUnreadNotificationCount > 0" class="rounded-full text-[10px] text-white bg-primary w-5 h-5 flex justify-center items-center">{{getUnreadNotificationCount}}</span>
        </AccountNavLink>
        <AccountNavLink :route-to="{name: 'account-past-events'}" :icon="TimelineIcon">
          Past events
        </AccountNavLink>
        <AccountNavLink :route-to="{name: 'account-followers'}" :icon="UserIcon">
          Followers
        </AccountNavLink>
        <AccountNavLink :route-to="{name: 'account-future-plans'}" :icon="TodoIcon" :hide-in-delegate-mode="true">
          Future plans
          <span class="rounded-full text-xs text-primary bg-secondary/30 w-5 h-5 flex justify-center items-center"><LockIcon class="!max-w-[60%]"/></span>
        </AccountNavLink>
        <AccountNavLink :route-to="{name: 'account-notes-to-self'}" :icon="ListIcon" :hide-in-delegate-mode="true">
          Notes to self
          <span class="rounded-full text-xs text-primary bg-secondary/30 w-5 h-5 flex justify-center items-center"><LockIcon class="!max-w-[60%]"/></span>
        </AccountNavLink>
        <AccountNavLink :route-to="{name: 'account-emergency-planning'}" :icon="SirenIcon">
         Emergency planning
          <span class="rounded-full text-xs text-primary bg-secondary/30 w-5 h-5 flex justify-center items-center"><LockIcon class="!max-w-[60%]"/></span>
        </AccountNavLink>
        <AccountNavLink route-to="/promotions" :icon="MegaphoneIcon" target="_blank">
          Promotions
        </AccountNavLink>
        <AccountNavLink :route-to="{name: 'account-logout'}" :icon="LogoutIcon">
          Logout
        </AccountNavLink>
      </ul>
    </nav>
  </div>
</template>

<script setup lang="ts">
import AccountNavLink from "~/components/account/AccountSidebar/AccountNav/AccountNavLink.vue";
import HomeIcon from "~/components/global/Icons/HomeIcon.vue";
import SliderIcon from "~/components/global/Icons/SliderIcon.vue";
import BellIcon from "~/components/global/Icons/BellIcon.vue";
import TimelineIcon from "~/components/global/Icons/TimelineIcon.vue";
import UserIcon from "~/components/global/Icons/UserIcon.vue";
import TodoIcon from "~/components/global/Icons/TodoIcon.vue";
import ListIcon from "~/components/global/Icons/ListIcon.vue";
import SirenIcon from "~/components/global/Icons/SirenIcon.vue";
import LogoutIcon from "~/components/global/Icons/LogoutIcon.vue";
import { useNotificationsStore } from "@/stores/notifications";
import { computed } from "vue";
import LockIcon from "~/components/global/Icons/LockIcon.vue";
import app from "@/App.vue";
import router from "@/router/index";
import MegaphoneIcon from "~/components/global/Icons/MegaphoneIcon.vue";

const { getUnreadNotifications } = useNotificationsStore();

const getUnreadNotificationCount = computed(() => {
  return getUnreadNotifications.length;
});

</script>

<style scoped>

</style>
