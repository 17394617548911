<template>
  <div class="mb-9">
      <div class="text-xs text-grey-200 mb-0.5">You're logged in as</div>
      <div class="text-md font-semibold mb-1.5">{{getAccount.name}}</div>
      <NuxtLink :to="{name: 'account-edit-profile'}" class="text-xs underline mb-1 flex items-end font-semibold">Edit profile
        <PencilIcon class="w-[1rem] ml-1 text-primary"/>
      </NuxtLink>
  </div>
</template>

<script setup lang="ts">
  import { useUserStore } from "@/stores/user";
  import PencilIcon from "~/components/global/Icons/PencilIcon.vue";
  const { getAccount } = useUserStore();
</script>

<style scoped>

</style>
