<template>
  <TransitionGroup name="list" tag="ul" class="sticky z-[29] top-[60px]" v-if="getNotices.length > 0">
    <li v-for="(notice) in getNotices" :class="getClasses(notice.type)" :key="notice">
<!--      <span class="timer-progress block h-2" :class="{width: notice.duration}"></span>-->
      <span class="flex justify-between px-4 py-2">
        <span class="flex items-center gap-2">
          <ErrorIcon v-if="notice.type === 'error'" class="w-4 text-white"/>
          {{ notice.message }}
        </span>
         <button @click="removeNotice(notice.id)">Close &times;</button>
      </span>
    </li>
  </TransitionGroup>
</template>

<script setup lang="ts">
import { useNoticesStore } from '@/stores/notices'
import Button from "~/components/global/Buttons/Button.vue";
import { useRoute, useRouter } from "vue-router";
import ErrorIcon from "~/components/global/Icons/ErrorIcon.vue";
const { getNotices, notices, removeNotice, clearNotices } = useNoticesStore();

const route = useRoute();
const router = useRouter();

const getClasses = (type: string) => {
  let classes = 'text-sm font-medium'

  switch (type) {
    case 'success':
      classes += ' bg-secondary text-primary'
      break
    case 'error':
      classes += ' bg-red text-white '
      break
    case 'info':
      classes += ' bg-primary text-white '
      break
  }

  return classes
}
</script>
