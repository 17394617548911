<template>
  <li
    class="account-nav-link mb-6 py-1 group relative after:content-[''] after:absolute after:-right-[calc(1.5rem-4px)] after:top-0 after:bottom-0 after:w-[5px] after:rounded-l-xl"
    :class="isActive ? 'after:bg-secondary' : ''"
  >
    <NuxtLink
      :to="routeTo"
      exact-active-class="font-semibold group-after:bg-secondary"
      class="text-sm flex items-center gap-3 leading-none"
      :class="isInDelegateMode && hideInDelegateMode ? 'cursor-not-allowed opacity-30' : ''"
      :target="target"
    >
      <component :is="icon" />
      <slot />
    </NuxtLink>
  </li>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { RouteLocationRaw, useRoute } from 'vue-router'
import { useDelegates } from '@/composables/useDelegates'

const { isInDelegateMode } = useDelegates()

const props = defineProps({
  routeTo: {
    type: Object as () => RouteLocationRaw
  },
  icon: {
    type: Object
  },
  hideInDelegateMode: {
    default: false
  },
  target: {
    type: String,
    default: '_self'
  }
})

const route = useRoute()

const isActive = computed(() => {
  return (props.routeTo as { name: string }).name === (route as { name: string }).name
})
</script>
