<template>
  <div class="bg-grey min-h-full">
    <AccountHeaderLayout>
      <template #beforeLogo>
        <Hamburger
          :is-mobile="screenState.getScreen.isMobile"
          :is-open="screenState.getScreen.isHamburgerMenuOpen"
          @toggle-menu="screenState.toggleMenu()"
        />
      </template>
      <template #afterLogo>
        <NuxtLink
          v-if="getTimeline.id"
          :to="{
            name: 'timeline-id',
            params: { id: getTimeline.id }
          }"
          class="ml-auto text-xs lg:text-sm font-semibold"
        >My Timeline
        </NuxtLink>
      </template>
    </AccountHeaderLayout>
    <div class="h-full mx-auto" :class="isInDelegateMode ? 'pt-[100px]' : 'pt-[60px]'">
      <div class="">
        <div class="flex">
          <div class="w-full hidden lg:block lg:max-w-[318px] xl:max-w-[364px] bg-white min-h-screen fixed z-20"
               :class="screenState.getScreen.isMobile && screenState.getScreen.isHamburgerMenuOpen ? '!block px-6' : 'hidden'">
            <div class="flex flex-col p-5 pt-11 lg:pl-16 xl:pl-24">
              <AccountProfileInfo />
              <AccountNav />
              <slot name="sidebar"></slot>
            </div>
          </div>
          <div class="w-full lg:ml-[318px] xl:ml-[364px]">
            <Notices />
            <div class="px-4 md:px-10 py-5 md:py-11">
              <div class="max-w-[80rem] mx-auto">
                <AccountHeader class="mb-8">
                  <h1 class="text-2xl text-secondary font-medium mb-4">{{ pageTitle }}</h1>
                  <p v-if="pageLead && pageLead.length" class="text-grey-300 text-sm max-w-[48rem]">
                    {{ pageLead }}
                  </p>
                </AccountHeader>
                <slot></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/user";
import { useDelegates } from "@/composables/useDelegates";
import { useScreenState } from "@/stores/screen";

import Notices from "~/components/account/Notices/Notices.vue";
import AccountHeader from "~/components/account/AccountSidebar/AccountHeader/AccountHeader.vue";
import AccountProfileInfo from "~/components/account/AccountSidebar/AccountProfileInfo/AccountProfileInfo.vue";
import AccountNav from "~/components/account/AccountSidebar/AccountNav/AccountNav.vue";

import { useRoute, useRouter } from "vue-router";
import { onMounted, onBeforeUnmount, ref, watch } from "vue";
import AccountHeaderLayout from "~/layouts/AccountHeaderLayout.vue";
import Hamburger from "~/components/global/Navigation/Hamburger.vue";

const { getTimeline, getAccount } = useUserStore();
const { isInDelegateMode } = useDelegates();

const isLoading = ref(true);

const screenState = useScreenState();

// Get Route
const route = useRoute();
const router = useRouter();

const pageTitle = computed(() => {
  return route?.meta?.title;
});

const pageLead = computed(() => {
  return route?.meta?.lead;
});

onMounted(async () => {
  window.addEventListener("resize", screenState.checkMobile);
  screenState.checkMobile();
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", screenState.checkMobile);
});

watch(
  () => route.path,
  async (newRoute, oldRoute) => {
    if (newRoute !== oldRoute && screenState.getScreen.isHamburgerMenuOpen && screenState.getScreen.isMobile) {
      screenState.toggleMenu();
    }
  }
);

useHead({
  title: route?.meta?.seoTitle ?? 'My Account',
  htmlAttrs: {
    lang: 'en',
    class: 'account-layout'
  },
  link: [
    {
      rel: 'apple-touch-icon',
      sizes: "180x180",
      href: '/apple-touch-icon.png'
    },
    {
      rel: 'icon',
      sizes: "32x32",
      href: '/favicon-32x32.png'
    },
    {
      rel: 'icon',
      sizes: "16x16",
      href: '/favicon-16x16.png'
    },
    {
      rel: 'manifest',
      href: '/site.webmanifest'
    },
  ],
  meta: [
    { name: 'robots', content: 'nofollow,noindex'}, // Added robots meta tag
  ],
});
</script>
